// @flow
import React from "react";
import styles from "./Content.module.scss";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { useSiteMetadata } from "../../../hooks";
import moment from "moment";

type Props = {
  body: string,
  title: string,
  coverimage: String,
  darkmodepost: Boolean,
  date: String,
};

const Content = ({ body, title, coverimage, darkmodepost, date }: Props) => {
  const { author } = useSiteMetadata();

  return (
    <div className={styles["content"]}>
      <div className={styles["content__cover"]}>
        <div className={styles["content__cover-wrapper"]}>
          <h1 className={styles["content__cover-wrapper-title"]}>{title}</h1>
          <div className={styles["content__cover-wrapper-meta-time"]}>
            <time dateTime={moment(date).format("MMMM DD, YYYY")}>
              Published: {moment(date).format("MMMM DD, YYYY")}
            </time>
          </div>
        </div>
      </div>
      <div
        className={styles[`content__body${darkmodepost ? "-darkmode" : ""}`]}
      >
        <MDXRenderer>{body}</MDXRenderer>
      </div>
    </div>
  );
};

export default Content;

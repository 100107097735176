// @flow
import React, { useEffect } from "react";
import { Link } from "gatsby";
import Author from "./Author";
import Content from "./Content";
import Meta from "./Meta";
import Tags from "./Tags";
import styles from "./Post.module.scss";
import type { Node } from "../../types";
import MailchimpForm from "../MailchimpForm/MailchimpForm";
import { useSiteMetadata } from "../../hooks";
import FacebookComments from "./FacebookComments";
import Share from "./Share";
import useDarkMode from "use-dark-mode";
import Divider from "../Sidebar/Divider/Divider";

type Props = {
  post: Node,
  coverimage: String,
};

const Post = ({ post }: Props) => {
  const { url, darkModeSite } = useSiteMetadata();
  const { body } = post;
  const { tagSlugs, slug } = post.fields;
  const { tags, title, date, coverimage, darkmodepost } = post.frontmatter;
  const setDarkMode = useDarkMode(darkmodepost);

  useEffect(() => {
    darkmodepost ? setDarkMode.enable() : setDarkMode.disable();
  });

  return (
    <div className={styles["post"]}>
      <div className={styles["post__cover-image"]}>
        <img src={coverimage}></img>
      </div>

      <div className={styles["post__home"]}>
        <Link to="/">
          <button className={styles["post__home-button"]}>All Articles</button>
        </Link>
      </div>

      <Content
        className={styles["post__content"]}
        body={body}
        title={title}
        coverimage={coverimage}
        darkmodepost={darkmodepost}
        date={date}
      />

      <div className={styles[`post__footer${darkmodepost ? "-darkmode" : ""}`]}>
        <Meta date={date} />
        {tags && tagSlugs && <Tags tags={tags} tagSlugs={tagSlugs} />}
        <Author />
        <Divider />
        {/* <MailchimpForm /> */}
        {/* <Divider /> */}
        <Share
          url={url}
          postSlug={slug}
          postTitle={post.frontmatter.title}
          hashtag={tags}
        />
        {/* <FacebookComments postSlug={slug} postTitle={post.frontmatter.title} /> */}
        <br />
      </div>
    </div>
  );
};

export default Post;

// @flow
import React from "react";
import { getContactHref } from "../../../utils";
import styles from "./Author.module.scss";
import { useSiteMetadata } from "../../../hooks";
import { withPrefix, Link } from "gatsby";

const Author = () => {
  const { author } = useSiteMetadata();

  return (
    <div className={styles["author"]}>
      <div>
        <a href={author.url}>
          <img
            src={author.photo}
            className={styles["author__profilepic"]}
          ></img>
        </a>
      </div>
      <div>
        <p className={styles["author__bio"]}>
          <h3>
            <a href={author.url}>
              <strong>{author.name}</strong>
            </a>
          </h3>
          {author.bio}
          {/* <a
            className={styles["author__bio-twitter"]}
            href={getContactHref("twitter", author.contacts.twitter)}
            rel="noopener noreferrer"
            target="_blank"
          >
            <strong>{author.name}</strong> on Twitter
          </a> */}
        </p>
      </div>
    </div>
  );
};

export default Author;

// @flow
import React from "react";
import { getContactHref, getIcon } from "../../../utils";
import Icon from "../../Icon";
import styles from "./Share.module.scss";

import {
  EmailShareButton,
  FacebookShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton,
} from "react-share";

import {
  EmailIcon,
  FacebookIcon,
  InstapaperIcon,
  LinkedinIcon,
  MailruIcon,
  OKIcon,
  PinterestIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";

import {
  FacebookShareCount,
  OKShareCount,
  PinterestShareCount,
  RedditShareCount,
  TumblrShareCount,
  VKShareCount,
} from "react-share";

type Props = {
  share: {
    [string]: string,
  },
  postSlug: string,
  postTitle: string,
  tags: array,
};

const Share = ({ share, url, postSlug, postTitle, tags }: Props) => {
  const shareUrl = url + postSlug.substr(1);
  const iconSize = 36;
  const isRound = true;

  return (
    <div className={styles["share"]}>
      <div className={styles["share__desc"]}>
        <h3 className={styles["share__desc-title"]}>Share and care</h3>
        <p className={styles["share__desc-paragraph"]}>
          {
            "If you found this piece of work valuable to your friends or colleagues, share with them!"
          }
        </p>
      </div>
      <div className={styles["share__list"]}>
        {[
          <FacebookShareButton url={shareUrl} quote={postTitle} hashtag={tags}>
            {/* <FacebookShareCount url={shareUrl} className={styles['share__list-item-icon']}/> */}
            <FacebookIcon round={isRound} size={iconSize} />
          </FacebookShareButton>,
          <TwitterShareButton url={shareUrl} title={postTitle}>
            <TwitterIcon round={isRound} size={iconSize} />
          </TwitterShareButton>,
          <RedditShareButton url={shareUrl} title={postTitle}>
            {/* <RedditShareCount url={shareUrl} className={styles['share__list-item-icon']}/> */}
            <RedditIcon round={isRound} size={iconSize} />
          </RedditShareButton>,
          <WhatsappShareButton url={shareUrl} title={postTitle}>
            <WhatsappIcon round={isRound} size={iconSize} />
          </WhatsappShareButton>,
          <EmailShareButton
            url={shareUrl}
            subject={postTitle}
            body={
              "Check out this interesting article from The Lost Kite:\n\n" +
              postTitle
            }
            separator={"\n"}
          >
            <EmailIcon round={isRound} size={iconSize} />
          </EmailShareButton>,
        ].map((social, index) => (
          <li className={styles["share__list-item"]} key={`social-${index}`}>
            {social}
          </li>
        ))}
      </div>
    </div>
  );
};

export default Share;

// @flow
import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Post from "../components/Post";
import { useSiteMetadata } from "../hooks";
import type { Mdx } from "../types";

type Props = {
  data: {
    mdx: Mdx,
  },
};

const PostTemplate = ({ data }: Props) => {
  const {
    title: siteTitle,
    subtitle: siteSubtitle,
    slug: slug,
  } = useSiteMetadata();
  const {
    title: postTitle,
    description: postDescription,
  } = data.mdx.frontmatter;
  const metaDescription =
    postDescription !== null ? postDescription : siteSubtitle;

  return (
    <Layout title={`${postTitle} - ${siteTitle}`} description={metaDescription}>
      <Post post={data.mdx}></Post>
    </Layout>
  );
};

export const query = graphql`
  query PostBySlug($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      id
      body
      fields {
        slug
        tagSlugs
      }
      frontmatter {
        date
        description
        tags
        title
        darkmodepost
        coverimage
      }
    }
  }
`;

export default PostTemplate;
